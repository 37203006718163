import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const LanguageButton = () => {
  const { i18n } = useTranslation();

  const handleClick = () => {
    const currentLang = i18n.language;
    const newLang = currentLang === 'fr' ? 'en' : 'fr';
    i18n.changeLanguage(newLang);
  };

  return (
    <ToggleButton onClick={handleClick}>
      <i className="fa-sharp fa-solid fa-globe" />
      <ToggleText>{i18n.language.toUpperCase()}</ToggleText>
    </ToggleButton>
  );
};

const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: rgb(118, 114, 111);
  border: 0.5px solid rgb(246, 234, 225);
  background-color: rgb(246, 234, 225);
`;

const ToggleText = styled.span`
  color: rgb(118, 114, 111);
  margin-left: 4px;
  text-transform: uppercase;
`;

export default LanguageButton;
