import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../styles/massages.scss';

const Massages = () => {
  const { t } = useTranslation();

  // const bookMassage = (e, massageType) => {
  //   const timeMassage = e.target.value;
  //   if (timeMassage !== '') {
  //     const link = `https://calendly.com/carolineaime7/massage-${massageType}-${timeMassage}min`;
  //     window.open(link, '_blank');
  //   }
  // };

  return (
    <section className="massage-section section">
      <div>
        <h1
          className="section-title"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {t('massages.title')}
        </h1>
      </div>
      <div className="massages-p-container">
        <div
          className="massage-p-container wellness"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div
            className="image-desktop-wellness"
            data-aos="fade-up"
            data-aos-duration="1000"
          />
          <div
            className="massage-p-text"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h3 className="massage-p-text-title">
              {t('massages.wellness-massage')}
            </h3>
            <p className="massage-p-text-description">
              {t('massages.back-legs')}
            </p>
            <p className="massage-p-text-time">30 min - 50€</p>
            <p className="massage-p-text-description">
              {t('massages.entire-body')}
            </p>
            <div className="time-container">
              <div className="time-slots">
                {' '}
                <p className="time-text">60 min - 80€</p>
                <p>&#x2022;</p>
                <p className="time-text">90 min - 110€</p>
              </div>
              <Link
                type="button"
                className="book-now-btn book-massage"
                to="/contact"
              >
                {' '}
                {t('massages.book')}
                {/* <option value="">
                {' '}
                {t('massages.book')}
              </option>
              <option value="30">30 min</option>
              <option value="60">60 min</option>
              <option value="90">90 min</option> */}
              </Link>
            </div>
          </div>
        </div>
        <div
          className="massage-p-container customized"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="massage-p-text">
            <h3 className="massage-p-text-title">{t('massages.customized')}</h3>
            <p className="massage-p-text-description">
              {t('massages.bespoke')}
            </p>
            <div className="time-container">
              <div className="time-slots">
                <p className="time-text">30 min - 50€</p>
                <p>&#x2022;</p>
                <p className="time-text">60 min - 80€</p>

                <p className="time-text">90 min - 110€</p>
              </div>
              <Link
                type="button"
                className="book-now-btn book-massage"
                to="/contact"
              >
                {' '}
                {t('massages.book')}
                {/* <option value="">{t('massages.book')}</option>
                  <option value="30">30 min</option>
                  <option value="60">60 min</option>
                  <option value="90">90 min</option> */}
              </Link>
            </div>
          </div>
          <div className="image-desktop-customized" />
        </div>
        <div
          className="massage-p-container ayurvedic"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="image-desktop-ayurvedic" />
          <div className="massage-p-text">
            <h3 className="massage-p-text-title">{t('massages.ayurvedic')}</h3>
            <p className="massage-p-text-description">
              {t('massages.ayurvedic-technique')}
            </p>
            <div className="time-container">
              <div className="time-slots">
                {' '}
                <p>60min - 90€</p>
                <p>&#x2022;</p>
                <p>90min - 120€</p>
              </div>
              <Link
                to="/contact"
                type="button"
                className="book-now-btn book-massage"
              >
                {' '}
                {t('massages.book')}
                {/* <option value="">
                  {' '}
                  {t('massages.book')}
                </option>
                <option value="60">60 min</option>
                <option value="90">90 min</option> */}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Massages;
