import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../../styles/massages.scss';
import '../../styles/gift-card.scss';

const Cards = ({
  selectedMassage,
  error,
  handleMassageSelect,
  handleCheckout,
}) => {
  const { t } = useTranslation();
  return (
    <section className="gift-card-section section">
      <div>
        <h1
          className="section-title"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {t('navigation.gift-card')}
        </h1>
      </div>
      <div className="massages-p-container">
        <div
          className="massage-p-container wellness"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div
            className="image-desktop-wellness"
            data-aos="fade-up"
            data-aos-duration="1000"
          />
          <div
            className="massage-p-text"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h3 className="massage-p-text-title">
              {t('massages.wellness-massage')}
            </h3>
            <p className="massage-p-text-description">
              {t('gift-card.massage')}
            </p>
            <div className="gift-card-prices-container">
              <p>30min - 50€</p>
              <p>&#x2022;</p>
              <p>60min - 80€</p>
              <p>&#x2022;</p>
              <p>90min - 110€</p>
            </div>
            <div className="time-container">
              <select
                className="book-now-btn book-massage"
                onChange={handleMassageSelect}
                value={selectedMassage}
                defaultValue={selectedMassage}
              >
                <option value="">{t('gift-card.select-time')}</option>
                <option value="wellness-30">30 min</option>
                <option value="wellness-60">60 min</option>
                <option value="wellness-90">90 min</option>
              </select>
            </div>
          </div>
        </div>
        <div
          className="massage-p-container customized"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="massage-p-text">
            <h3 className="massage-p-text-title second-card">
              {t('massages.customized')}
            </h3>
            <p className="massage-p-text-description second-card">
              {t('massages.bespoke')}
            </p>
            <div className="gift-card-prices-container second-card">
              <p>30min - 50€</p>
              <p>&#x2022;</p>
              <p>60min - 80€</p>
              <p>&#x2022;</p>
              <p>90min - 110€</p>
            </div>
            <div className="time-container">
              <select
                className="book-now-btn book-massage"
                onChange={handleMassageSelect}
                defaultValue={selectedMassage}
                value={selectedMassage}
              >
                <option value="">{t('gift-card.select-time')}</option>
                <option value="customized-30">30 min</option>
                <option value="customized-60">60 min</option>
                <option value="customized-90">90 min</option>
              </select>
            </div>
          </div>
          <div className="image-desktop-customized" />
        </div>
        <div
          className="massage-p-container ayurvedic"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="image-desktop-ayurvedic" />
          <div className="massage-p-text">
            <h3 className="massage-p-text-title">{t('massages.ayurvedic')}</h3>
            <p className="massage-p-text-description">
              {t('massages.ayurvedic-technique')}
            </p>
            <div className="gift-card-prices-container">
              <p>60min - 90€</p>
              <p>&#x2022;</p>
              <p>90min - 120€</p>
            </div>
            <div className="time-container">
              <select
                className="book-now-btn book-massage"
                onChange={handleMassageSelect}
                value={selectedMassage}
                defaultValue={selectedMassage}
              >
                <option value="">{t('gift-card.select-time')}</option>
                <option value="ayurvedic-60">60 min</option>
                <option value="ayurvedic-90">90 min</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <p className={`error-message ${error ? 'active' : ''}`}>
        *
        {t('gift-card.error')}
      </p>

      <button
        className="submit-btn cart-btn"
        type="button"
        onClick={handleCheckout}
      >
        {t('gift-card.add-cart')}
      </button>
    </section>
  );
};

export default Cards;

Cards.propTypes = {
  selectedMassage: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  handleMassageSelect: PropTypes.func.isRequired,
  handleCheckout: PropTypes.func.isRequired,
};
