import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../styles/footer.scss';
import SocialLogos from './SocialLogos';
import Map from './Map';
import ContactForm from './ContactForm';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-container">
      <div className="footer-items">
        <div
          className="footer-item links"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h3 className="title-footer">{t('footer.links')}</h3>
          <ul className="footer-list">
            <li className="nav-item">
              <Link to="/">{t('footer.home')}</Link>
            </li>
            <li className="nav-item">
              <Link to="/about">{t('footer.about-me')}</Link>
            </li>
            <li className="nav-item">
              <Link to="/sophrology">{t('footer.sophrology')}</Link>
            </li>
            <li className="nav-item">
              <Link to="/massages">{t('footer.massages')}</Link>
            </li>
            <li className="nav-item">
              <Link to="/gift-card">{t('navigation.gift-card')}</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact">{t('footer.contact')}</Link>
            </li>
          </ul>
        </div>
        <div
          className="footer-item contact"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h3 className="title-footer">{t('footer.contact')}</h3>
          <p className="location-text-footer">0033/6 74 81 20 09</p>
          <div>
            <SocialLogos />
          </div>
        </div>
        <div
          className="footer-item map-container"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h3 className="title-footer">{t('contact.address')}</h3>
          <p className="location-text-footer">19 impasse des Valérianes,</p>
          <p className="location-text-footer 2">01170 Crozet</p>
          <div className="map-container-footer">
            <Map />
          </div>
        </div>
        <div
          className="footer-item form"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <ContactForm />
        </div>
      </div>
      <div className="copyright-section">
        <div>© Caroline Aimé Healing, All Rights Reserved.</div>
      </div>
    </div>
  );
};

export default Footer;
