import Carousel from 'nuka-carousel';
import '../../styles/citas.scss';
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const Citas = () => {
  const { t } = useTranslation();
  return (
    <div className="carousel">
      <Carousel
        slidesToShow={1}
        slidesToScroll={1}
        adaptiveHeigh
        adaptiveHeightAnimation
        dragging
        cellAlign="center"
        className="carousel-container"
        autoplay
        autoplayInterval={5000}
        wrapAround
        renderCenterLeftControls={({ previousSlide }) => (
          <button
            className="slide-button slide-prev"
            type="button"
            onClick={previousSlide}
          >
            <MdOutlineArrowBackIosNew />
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button
            className="slide-button slide-next"
            type="button"
            onClick={nextSlide}
          >
            <MdOutlineArrowForwardIos />
          </button>
        )}
      >
        <div className="slide-content">
          <p>{t('citas.first')}</p>
          <p className="slide-author">{t('authors.first')}</p>
        </div>
        <div className="slide-content">
          <p>{t('citas.second')}</p>
          <p className="slide-author">{t('authors.second')}</p>
        </div>
        <div className="slide-content">
          <p>{t('citas.third')}</p>
          <p className="slide-author">{t('authors.third')}</p>
        </div>
        <div className="slide-content">
          <p>{t('citas.fourth')}</p>
          <p className="slide-author">{t('authors.fourth')}</p>
        </div>
        <div className="slide-content">
          <p>{t('citas.fifth')}</p>
          <p className="slide-author">{t('authors.fifth')}</p>
        </div>
      </Carousel>
    </div>
  );
};

export default Citas;
