import CircleLoader from 'react-spinners/CircleLoader';

const Spinning = () => (
  <div className="layout">
    {' '}
    <CircleLoader color="rgb(41,41,41)" size={130} />
    <h1>Loading...</h1>
  </div>
);
export default Spinning;
