import { useForm, ValidationError } from '@formspree/react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

const ContactForm = () => {
  const { t } = useTranslation();
  const [state, handleSubmit] = useForm('xwkjarvb');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccessMessage('');
    }, 4000);

    return () => clearTimeout(timer);
  }, [successMessage]);

  const handleFormSubmit = async (event) => {
    await handleSubmit(event);
    if (state.succeeded) {
      setSuccessMessage(t('contact.submission-msg'));
      event.target.reset();
    }
  };

  return (
    <>
      {successMessage && (
        <div className="submission-message-container">{successMessage}</div>
      )}
      <form
        method="POST"
        onSubmit={handleFormSubmit}
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h3 className="title-footer">{t('footer.touch')}</h3>

        <input
          id="name"
          type="text"
          name="name"
          className="footer-input"
          placeholder={t('contact.name')}
          required
        />
        <ValidationError prefix="Name" field="name" errors={state.errors} />

        <input
          id="email"
          type="email"
          name="email"
          className="footer-input"
          placeholder={t('contact.email')}
          required
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />

        <textarea
          id="message"
          name="message"
          placeholder={t('contact.message')}
          className="footer-input textarea"
          required
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />

        <button
          type="submit"
          className="submit-btn"
          disabled={state.submitting}
        >
          {t('contact.send')}
        </button>
        <ValidationError errors={state.errors} />
      </form>
    </>
  );
};

export default ContactForm;
