import React from 'react';
import AboutPhone from './About';
import '../styles/home.scss';
import {
  Horaries,
  Hero,
  SocialLogos,
  Citas,

} from '../components/features/index';

const Home = () => (
  <section className="entire-section">
    <Hero />
    <AboutPhone home />
    <Citas />
    <SocialLogos />
    <Horaries />
  </section>
);

export default Home;
