import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SocialLogos } from '../components/features';
import '../styles/thanks.scss';

const Error = () => {
  const { t } = useTranslation();
  return (
    <section className="thanks-page section">
      <h1>Ops...</h1>
      <p>{t('error')}</p>
      <div className="buttons-container">
        <Link className="submit-btn" to="/">
          {t('navigation.home')}
        </Link>
      </div>
      <SocialLogos />
    </section>
  );
};

export default Error;
