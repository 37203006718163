const priceIds = {
  'wellness-30': 'price_1NokJmDja38ybjPviScQaWGU',
  'wellness-60': 'price_1NokJmDja38ybjPvwbCEz1XL',
  'wellness-90': 'price_1NokJmDja38ybjPvtPEwHGgO',
  'ayurvedic-60': 'price_1NokM2Dja38ybjPvAOmITD5A',
  'ayurvedic-90': 'price_1NokM2Dja38ybjPvAOmITD5A',
  'customized-30': 'price_1NokJmDja38ybjPviScQaWGU',
  'customized-60': 'price_1NokJmDja38ybjPvwbCEz1XL',
  'customized-90': 'price_1NokJmDja38ybjPvtPEwHGgO',
};

export default priceIds;
