import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/contact.scss';
import {
  Map,
  Horaries,
  ContactForm,
  SocialLogos,
}
  from '../components/features/index';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <section className="contact-section section">
      <h1 className="section-title" data-aos="fade-up" data-aos-duration="1000">
        Contact
      </h1>
      <main className="contact-main">
        <Horaries data-aos="fade-up" data-aos-duration="1000" />
        <article
          className="location-container"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h3 className="phone-title">{t('contact.address')}</h3>
          <p
            className="location-text"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            19 impasse des Valérianes, 01170 Crozet
          </p>
          <div
            className="map-container"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <Map />
          </div>
        </article>
        <article
          className="contact-container"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <article className="phone-container">
            <h3 className="phone-title">{t('contact.phone')}</h3>
            <p className="phone-number">0033/6 74 81 20 09</p>
            <p className="contact-text">{t('contact.contact-text')}</p>
            <SocialLogos />
          </article>
        </article>
        <ContactForm />
      </main>
    </section>
  );
};

export default Contact;
