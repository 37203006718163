import { BiTimeFive } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import '../../styles/horaries.scss';

const Horaries = () => {
  const { t } = useTranslation();

  return (
    <article
      className="hours-location-container"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="opening-hours-container-about">
        <h2 className="opening-hours-title">{t('contact.opening-hours')}</h2>
        <div className="title-line" />
        <div className="opening-hours-text-container">
          <h3 className="week-days">{t('contact.week-days')}</h3>
          <div className="hours-div">
            <p className="hours">
              {' '}
              <BiTimeFive className="time-icon" />
              {' '}
              {t('contact.week-days-hours')}
            </p>
          </div>
          <h3 className="saturday-wednesday">
            {t('contact.saturday-wednesday')}
          </h3>
          <div className="hours-div">
            <p className="hours">
              <BiTimeFive className="time-icon" />
              {' '}
              {t('contact.weekend-hours')}
            </p>
          </div>
          <h3 className="sunday">{t('contact.sunday')}</h3>
          <p className="hours">{t('contact.close')}</p>
        </div>
      </div>
    </article>
  );
};

export default Horaries;
