import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../../assets/icons/logo3.png';
import '../../../styles/navigation.scss';
import LanguageButton from '../LanguageButton';

const Navigation = () => {
  const { t } = useTranslation();

  return (
    <nav className="nav-bar">
      <div className="logo">
        <Link to="/">
          <img className="logo-navbar" alt="logo" src={Logo} />
        </Link>
      </div>
      <ul className="menu-navbar">
        <li className="nav-item">
          <Link to="/">{t('navigation.home')}</Link>
        </li>
        <li className="nav-item">
          <Link to="/massages">{t('navigation.massages')}</Link>
        </li>
        <li className="nav-item">
          <Link to="/sophrology">{t('navigation.sophrology')}</Link>
        </li>
        <li className="nav-item">
          <Link to="/about">{t('navigation.about-me')}</Link>
        </li>
        <li className="nav-item">
          <Link to="/gift-card">{t('navigation.gift-card')}</Link>
        </li>
        <li className="nav-item">
          <Link to="/contact">{t('navigation.contact')}</Link>
        </li>
        <LanguageButton />
      </ul>
      <Outlet />
    </nav>
  );
};

export default Navigation;
