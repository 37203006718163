import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles/error.scss';

const Default404 = () => {
  const { t } = useTranslation();
  return (
    <main className="error-container">
      <h1>404</h1>
      <p>
        Ooops....
        {t('navigation.lost')}
      </p>
      <Link className="error" to="/">{t('navigation.home')}</Link>
    </main>
  );
};

export default Default404;
