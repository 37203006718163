import { useTranslation } from 'react-i18next';
import '../styles/about.scss';
import meImage from '../assets/images/image5.jpeg';

const About = () => {
  const { t } = useTranslation();
  return (
    <section className="section about-container">
      <h1 className="section-title" data-aos="fade-up" data-aos-duration="1000">
        {t('about.about-me')}
      </h1>
      <h3 className="about-name" data-aos="fade-up" data-aos-duration="1000">
        {t('about.name')}
      </h3>
      <div className="text-image" data-aos="fade-up" data-aos-duration="1000">
        <img
          className="portrait-image"
          alt="me"
          src={meImage}
          data-aos="fade-up"
          data-aos-duration="1000"
        />
        <div className="about-text">
          <p
            className="paragraph-1"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {t('about.my-work')}
          </p>
          <p
            className="paragraph-2"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {t('about.techniques')}
          </p>
          <p
            className="paragraph-2"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {t('about.passionate')}
          </p>
          <p
            className="last-paragraph"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {t('about.from')}
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
