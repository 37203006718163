import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from './ScrollToTOp';
import {
  Home,
  Contact,
  Sophrology,
  Massages,
  Default404,
  About,
  GiftCard,
  Checkout,
  Thanks,
  Error,
} from './pages/index';

import {
  Footer,
  Navigation,
  NavigationMobile,
} from './components/features/index';

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    AOS.init();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        {width < 1000 ? <NavigationMobile /> : <Navigation />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/sophrology" element={<Sophrology />} />
          <Route path="/error" element={<Error />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/massages" element={<Massages />} />
          <Route path="/gift-card" element={<GiftCard />} />
          <Route path="*" element={<Default404 />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
