import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/sophrology.scss';
import ContactForm from '../components/features/ContactForm';

const Sophrology = () => {
  const { t } = useTranslation();
  return (
    <section className="sp-section section">
      <h1 className="section-title" data-aos="fade-up" data-aos-duration="1000">
        {t('sophrology.sophrology')}
      </h1>
      <main className="sophrology-main">
        <div className="explanation-container">
          <div className="what-is">
            <h2
              className="what-is-question"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {t('sophrology.what-is')}
            </h2>
            <p
              className="what-is-text"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {t('sophrology.what-description')}
            </p>
          </div>
          <div className="who-for">
            <h2
              className="who-for-question"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {t('sophrology.who-for')}
            </h2>
            <p
              className="who-description"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {t('sophrology.who-description')}
            </p>
          </div>
        </div>
        <div className="extra-info-container">
          <div
            className="offer-container"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <p className="offer-text">{t('sophrology.offer')}</p>
          </div>
          <div
            className="duration-container"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <p className="duration-text">{t('sophrology.duration')}</p>
          </div>
        </div>
        <div
          className="sessions-container"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="sessions">
            <div className="first-session">
              <i className="fa-solid fa-circle-dot" />
              <p className="first-text">{t('sophrology.first-session')}</p>
            </div>
            <div className="follow-up">
              <i className="fa-solid fa-circle-dot" />
              <p className="follow-text">{t('sophrology.follow-up')}</p>
            </div>
          </div>
        </div>
        <ContactForm data-aos="fade-up" data-aos-duration="1000" />
      </main>
    </section>
  );
};

export default Sophrology;
