/* eslint-disable import/no-extraneous-dependencies */

import { FaFacebookF } from 'react-icons/fa';
import { BsWhatsapp, BsInstagram } from 'react-icons/bs';
import styled from 'styled-components';
import { AiOutlineMail } from 'react-icons/ai';

const SocialLogos = () => (
  <Wrapper
    className="social-logos-container"
    data-aos="fade-up"
    data-aos-duration="1000"
  >
    <div className="logo-container">
      <a
        href="https://www.facebook.com/profile.php?id=61550533573400"
        target="_blank"
        rel="noreferrer"
      >
        <FaFacebookF className="logo" />
      </a>
    </div>
    <div className="logo-container">
      <a href="https://wa.me/33674812009" target="_blank" rel="noreferrer">
        <BsWhatsapp className="logo" />
      </a>
    </div>
    <div className="logo-container">
      <a
        href="https://instagram.com/caroline_aime_healing?igshid=OGQ5ZDc2ODk2ZA=="
        target="_blank"
        rel="noreferrer"
      >
        <BsInstagram className="logo" />
      </a>
    </div>
    <div className="logo-container">
      <a
        href="mailto:caroline.aime@yahoo.fr"
        target="_blank"
        rel="noreferrer"
      >
        <AiOutlineMail className="logo mail" />
      </a>

    </div>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  .logo {
    font-size: 18px;
    color: white;
  }
  .mail {
    font-size: 25px;
  }
  .logo-container {
    background-color: rgb(66, 79, 33);
    width: 40px;
    height: 40px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.4s ease-in-out;
  }
  .logo-container:hover {
    transform: translateY(10px);
  }
`;

export default SocialLogos;
