import React from 'react';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();
  return (
    <section className="hero-section">
      <h1 className="name-text">{t('home.name')}</h1>
      <div className="home-text">
        <p className="practice-text">{t('home.practice')}</p>
        <p className="practice-text">01170 Crozet, Pays de Gex</p>
      </div>
    </section>
  );
};

export default Hero;
