import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/massages.scss';
import '../styles/gift-card.scss';
import { loadStripe } from '@stripe/stripe-js';
import priceIds from '../utils';
import { Cards, Spinning } from '../components/features';

const GiftCard = () => {
  const [selectedMassage, setSelectedMassage] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stripe, setStripe] = useState(null);
  const navigate = useNavigate();

  const getPublishableKey = async () => {
    const response = await fetch('http://localhost:3001/payment/get-client-key');
    const publishableKey = await response.text();
    return publishableKey;
  };

  useEffect(() => {
    const loadStripeInstance = async () => {
      const publishableKey = await getPublishableKey();
      const stripe = await loadStripe(publishableKey);
      setStripe(stripe);
    };
    loadStripeInstance();
  }, []);

  const handleMassageSelect = (event) => {
    setSelectedMassage(event.target.value);
  };

  const handleCheckout = async () => {
    if (selectedMassage === '') {
      setError(true);
    } else {
      setError(false);
      if (stripe) {
        setIsLoading(true);

        const selectedPriceId = priceIds[selectedMassage];

        const response = await fetch(
          'http://localhost:3001/payment/create-checkout-session',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ selectedMassage }),
          },
        );

        if (!response.ok) {
          setIsLoading(false);
          navigate('/error');
        } else {
          const session = await response.json();
          await stripe.redirectToCheckout({
            lineItems: [{ price: selectedPriceId, quantity: 1 }],
            sessionId: session.id,
            mode: 'payment',
            successUrl: 'http://localhost:3000/thanks',
          });
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        navigate('/error');
      }
    }
  };
  return (
    <section>
      {isLoading && <Spinning />}

      <Cards
        selectedMassage={selectedMassage}
        error={error}
        handleMassageSelect={handleMassageSelect}
        handleCheckout={handleCheckout}
      />
    </section>
  );
};

export default GiftCard;
